/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import "../scss/main.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      
      <>{children}</>
      <footer className="footer">
        <div className="footer__logos">
            <div className="footer-logos">
                <div className="footer-logos__main desktop-only">
                    
                    <div><a href="https://glaukomforeningen.no/"><StaticImage src="../images/logos/norsk-glaukomforening.png" alt="Norsk Glakomforening Logo" width="99" height="58"/></a></div>
                    <div><a href="https://www.interoptik.no/"><StaticImage src="../images/logos/interoptik.png" alt="Interoptik Logo" width="118" height="24" /></a></div>
                    <div><a href="https://www.santen.no/"><StaticImage src="../images/logos/santen.png" alt="Santen Logo" /></a></div>

                </div>
                <div className="footer-logos__secondary mobile-only">
                    <a href="https://www.santen.no/"><StaticImage src="../images/logos/norsk-glaukomforening.png" alt="Norsk Glakomforening Logo" width="99" height="58"/></a>
                    <a href="https://www.interoptik.no/"><StaticImage src="../images/logos/interoptik.png" alt="Interoptik Logo" width="118" height="24" /></a>
                    <a href="https://www.santen.no/"><StaticImage src="../images/logos/santen.png" alt="Santen Logo" /></a>
                </div>
            </div>
        </div>
        <div className="footer-copy-logos">
            <div className="footer-copy-logos__copy">
                <p>Innholdet på sjekksynet.no er redigert og forenklet for å gi leseren kort, relevant og nyttig kunnskap om grønn stær (glaukom).</p>
                <ol>
                    <li><a target="_blank" rel="noreferer" href="https://www.helsenorge.no/sykdom/oyesykdommer/glaukom/">Helsenorge – Grønn stær - glaukom</a></li>
                    <li><a target="_blank" rel="noreferer" href="https://www.felleskatalogen.no/medisin/sykdom/gronn-ster">Felleskatalogen – Grønn stær</a></li>
                    <li><a target="_blank" rel="noreferer" href="https://www.eugs.org/eng/guidelines.asp">European Glaucoma Society - Guidelines</a></li>
                </ol>
                <p>Sist oppdatert: 2022-04-01 | PP-DED-NO-0007 | © Santen 2022 | NP-GLAU-NO-0002/DEC2021 <Link to="/brukervilkar">Brukervilkår</Link></p>
            </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
